import rlogo from "../images/rwot-logo 1.png";
import rectangle from "../images/Rectangle 28.png";

const Paper = () => {
  return (
    <div className="w-full  flex md:flex-row flex-col justify-around items-center text-white  md:p-[4rem] p-[1rem] rounded-r-[300px] relative bg-[url('/src/images/about.png')] bg-center bg-no-repeat" >
      <img src={rectangle} alt="" className="absolute h-full w-full left-0 " />
      <aside className="md:basis-[50%] basis-[auto] px-[2rem] z-10">
        <h1 className="text-lg font-bold my-[1rem]">
          A WHITE PAPER FROM RWOT XI: THE HAGUE
        </h1>
        <p className="font-bold italic text-lg">
          Composing Credentials via <br />
          LinkedClaims and Cryptographic Binding{" "}
        </p>
        <p className="text-[#80AB9E] my-[2rem]">
          by Philip D. Long(T3 Innovation Network, Georgetown University & ASU),
          Dmitri Zagidulin (MIT Digital Credentials Consortium) & Golda Velez
            <a href="http://cooperation.org/" className="underline text-white">  (Cooperation.org)</a>
        </p>
        <button className=" md:block hidden border border-[#00E3A5] p-[0.5rem] px-[4rem]">
          <a href=" https://github.com/WebOfTrustInfo/rwot11-the-hague/blob/master/final-documents/composable-credentials.pdf">
            Full Paper
          </a>
        </button>
      </aside>
      <aside>
        <img src={rlogo} alt="" className=" w-[300px] " />
        <a href=" https://github.com/WebOfTrustInfo/rwot11-the-hague/blob/master/final-documents/composable-credentials.pdf" className="z-20 relative">
          <button className=" md:hidden block border m-auto mt-4 border-[#00E3A5] p-[0.5rem] px-[4rem]">
            Learn More
          </button>
        </a>
      </aside>
    </div>
  );
};

export default Paper;
