import Footer from "./Footer";
import Navbar from "./Navbar";
import ChatIcon from "@mui/icons-material/Chat";
import { useState } from "react";
import Chat from "./Chat";
import CloseIcon from "@mui/icons-material/Close";

const Layout = ({ children }) => {
  const [showChat, setShowChat] = useState(false);
  function chatDisplay() {
    setShowChat(!showChat);
  }
  return (
    <>
      <section>
        <Navbar />,
        {children}
        <Footer />
        {/* {showChat ? (
          <div
            className="w-[100px] h-[100px] text-2xl heartbeat rounded-full fixed right-[2%] bottom-[4%] z-10 cursor-pointer bg-[#00E3A5] flex items-center justify-center"
            onClick={chatDisplay}
          >
            <CloseIcon className="text-2xl" />
          </div>
        ) : (
          <div
            className="w-[100px] h-[100px] text-2xl heartbeat rounded-full fixed right-[2%] bottom-[4%] z-10 cursor-pointer bg-[#00E3A5] flex items-center justify-center"
            onClick={chatDisplay}
          >
            <ChatIcon className="text-2xl" />
          </div>
        )}
        <div className="w-[120px] ping h-[120px] rounded-full fixed right-[1.5%] bottom-[2%] bg-white"></div>
        {showChat &&  <div className="md:w-[400px] w-[320px] h-[80vh]  fixed right-[2%] bottom-[17%]">
          <Chat />
        </div>
        } */}
      </section>
    </>
  );
};

export default Layout;
