import whatscook from "../images/whatsCookinLogoVert.png";
import compose from "../images/compose 1.png";
import human from "../images/human 1.png";
import cio from "../images/cio.png";
import quote from "../images/quote.png";
import islong from "../images/ISOLOGO-OPTIONS-02-Custom 1.png";
import groupe from "../images/Group 102.png"
import bg from '../images/about.png'
import ceramic from '../images/Ceramic.svg'
import humanimg from '../images/human.svg'
const About = () => {
  return (
    <section className="flex flex-col items-center justify-center relative"  >
      <img src={bg} className='absolute top-0 left-0 w-full' alt="" />
      <h2 className="text-3xl font-bold text-white py-5">
        Partners and Protocols
      </h2>

      <p className="md:w-2/5 text-[#80AB9E] p-4 text-center relative ">
        <img src={quote} alt="" className="w-[80px] absolute z-10" />
        <span className="z-20 relative">
          {" "}
          LinkedTrust claims are in use in{" "}
          <a href="https://app.whatscookin.us/">WhatCookin Communities</a> and
          power discovery of{" "}
          <a href="https://CouponsforGood.us">Coupons for Good</a>, a way to
          easily shop sustainably. They are published on{" "}
          <a href="https://composedb.js.org">ComposeDB Ceramic Streams</a> and
          we are in the process of setting up a LinkedTrust-powered Oracle on
          the <a href="https://www.humanprotocol.org/">HUMAN Protocol</a>.
        </span>
      </p>
      <div className="md:my-[2rem] my-[1rem] flex flex-col items-center justify-center">
        <aside className="flex z-20 ">
          <a href=" https://whatscookin.us/" target="_blank" rel="noopener noreferrer">
            <img
              src={whatscook}
              alt=""
              className="block  md:h-[100px] h-[60px] md:w-[100px]  w-[60px]  object-contain"
            />
          </a>

          <a href="http://couponsforgood.us " target="_blank" rel="noopener noreferrer">
            <img
              src={islong}
              alt=""
              className="block mx-6 md:h-[100px] h-[60px] md:w-[100px] w-[60px] object-contain"
            />
          </a>
          <a href="http://cooperation.org/ " target="_blank" rel="noopener noreferrer">
            <img
              src={cio}
              alt=""
              className="block  md:h-[100px] h-[60px] md:w-[100px] w-[60px] object-contain"
            />
          </a>

        </aside>
        <h2 className="text-center font-bold text-white text-3xl my-[2rem]">Built With</h2>
        <aside className="flex z-20">
          <a href="https://humanprotocol.org/" target='_blank'>
            <img
              src={humanimg}
              alt=""
              className="block mx-6 h-[100px]  w-[150px]  object-contain"
            />.
          </a>
          <a href="https://ceramic.network" target="_blank" rel="noopener noreferrer">
            <img
              src={ceramic}
              alt=""
              className="block mx-6 h-[100px]  w-[150px]  object-contain"
            />˝
          </a>

          {/* <img
            src={compose}
            alt=""
            className="block mx-6 md:h-[100px] h-[60px] md:w-[100px] w-[60px] object-contain"
          /> */}
        </aside>
      </div>
    </section>
  );
};

export default About;
