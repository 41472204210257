import { useState } from "react";
import logo from '../images/logo.svg'
import { AiOutlineClose } from "react-icons/ai";
import { motion } from "framer-motion";
import { HashLink as Link } from "react-router-hash-link";
import menu from '../images/Menu.png'

const Navbar = () => {
  const [mobile, setMobile] = useState(false);

  const toggleMobile = () => {
    setMobile(!mobile);
  };

  return (
    <section className="fixed top-0 w-full z-40">
      <div className="flex justify-between  bg-[#09251E] md:p-[1.5rem] p-[0.7rem]  m-auto ">
        <h2 className="font-normal font-roboto  md:text-3xl text-2xl leading-9 text-green-100 flex items-center">
         <img src={logo} alt=""  className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] mr-2" /> LinkedTrust
        </h2>
        <div className="flex items-center">
          <ul className="md:flex hidden">
            <Link smooth to="#about">
              <li className="text-white mx-[1rem] font-bold">About</li>
            </Link>
            <Link smooth to="#paper">
              <li className="text-white mx-[1rem] font-bold">White Paper</li>
            </Link>
            <Link smooth to="#open">
              <li className="text-white mx-[1rem] font-bold">Open source</li>
            </Link>
          </ul>
          <p className="px-[1.5rem] py-[0.5rem] border-2 border-green-500 text-white font-bold mx-[1rem]">
            <a href="https://live.linkedtrust.us/">Live Demo</a>
          </p>
          {!mobile && (
            <img src={menu} alt='menu'
              className="text-white text-2xl font-bold cursor-pointer block md:hidden"
              onClick={toggleMobile}
            />
          )}
          {mobile && (
            <AiOutlineClose
              className="text-white text-2xl font-bold cursor-pointer block md:hidden"
              onClick={toggleMobile}
            />
          )}

          {mobile && (
            <motion.ul
              className="flex flex-col fixed left-0 bottom-0 items-center top-[80px] justify-center  h-[100vh] w-[100vw] bg-green-600 z-20"
              initial={{ x: "-100vw" }}
              animate={{ x: 0 }}
              transition={{ type: "spring" }}
            >
              <Link smooth to="#about">
                <li
                  className="text-white mx-[1rem] font-bold my-[2rem] text-3xl"
                  onClick={() => setMobile(false)}
                >
                  About
                </li>
              </Link>
              <Link smooth to="#paper">
                <li
                  className="text-white mx-[1rem] font-bold  my-[2rem] text-3xl "
                  onClick={() => setMobile(false)}
                >
                  White Paper
                </li>
              </Link>
              <Link smooth to="#open">
                <li
                  className="text-white mx-[1rem] font-bold  my-[2rem] text-3xl "
                  onClick={() => setMobile(false)}
                >
                  Open source
                </li>
              </Link>
            </motion.ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default Navbar;
