import { FaMastodon } from "react-icons/fa";
import ceramic from '../images/Ceramic.svg'

const Footer = () => {
  return (
    <footer className=" bg-[#092326] mt-[1rem] min-h-[100%]">
      <div className=" bg-[url('/src/images/global.png')] flex justify-between min-h-[70vh]  md:p-[2rem] p-0 bg-center bg-no-repeat flex-wrap">
        <aside className="md:basis-[49%] basis-auto md:px-[3rem] px-[0.5rem]">
          <h2 className="text-white font-bold my-[1rem] text-2xl">About</h2>
          <p className="text-[#80AB9E] my-[1rem]">
            Key work on LinkedTrust open technologies has been performed by{" "}
            <a href="https://whatscookin.us/">What's Cookin' Inc</a>, a Public
            Benefit corporation that shares equity with builders and investors
            via smart tokens over future liquidity.
          </p>
          <div>
            <p className="text-white font-bold my-[1.5rem] text-xl">
              Social Media
            </p>
            <p>
              {" "}
              <a href="https://mas.to/@gvelez17">
                <span className="border-2 inline-block border-white text-white p-[0.5rem] rounded-full">
                  <FaMastodon />
                </span>
              </a>
            </p>
            <p className="text-white mt-[3rem] md:block hidden">All rights reserved@2021</p>
            <p className="text-white mt-[3rem] md:block hidden flex gap-2">Powered by Ceramic <a href="https://ceramic.network" target="_blank" rel="noopener noreferrer">  <img src={ceramic} className='w-[30%]' alt="" /></a> </p>
          </div>
        </aside>
        <aside className="md:basis-[49%] basis-auto text-white p-[1rem] w-full">
          <h2>Early Beta Access</h2>

          <form
            action="https://whatscookin.us20.list-manage.com/subscribe/post?u=9705380b0be85e2a833d0aa52&amp;id=3fd38320dc&amp;f_id=00a874eaf0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
            novalidate
          >
            <div className="h-[50px] border border-[#00E3A5] md:w-[450px] w-[350px] rounded-lg">
              <input
                type="email"
                value=""
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Email Address"
                className="w-[70%]  h-full  bg-transparent text-black outline-none p-[0.5rem] "
              />
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="w-[30%] h-full bg-[#00E3A5] rounded-lg"
              />
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_9705380b0be85e2a833d0aa52_3fd38320dc"
                  tabindex="-1"
                  value=""
                />
              </div>
              <div className="clear">

              </div>
            </div>
          </form>


          <figure className="flex md:flex-row flex-col  md:items-start justify-between items-center mt-[4rem] gap-4 text-[#80AB9E]">
            <ul className="md:text-left text-center">
              <li className="text-white font-bold text-xl">More Info</li>
              <li classname="mt-[1rem]">
                <a href="https://cooperation.org/linked_trust/">
                  High Level Overview
                </a>
              </li>
              <li className="mt-[1rem]">
                <a href="https://whatscookin.us/blogs/">Community Blog</a>
              </li>
              <li className="mt-[1rem]">
                <a href="https://couponsforgood.us/blogs/">
                  Products & Companies Blog
                </a>
              </li>
              <li className="mt-[1rem]">
                <a href="https://docs.google.com/document/d/1ndB-UczN9irnkf0GeR3mg7jsyVrjppXLTxvOBAm15KI/edit">
                  In the News and on the Air
                </a>
              </li>
            </ul>
            <ul className="md:text-left text-center">
              <li className="text-white font-bold text-xl">Contact</li>
              <li className="mt-[1rem]">
                <a href="https://whatscookin.us/contact-us/">Contact Us</a>
              </li>
              <li className="mt-[1rem]">
                <a href="https://app.whatscookin.us/circle/whatscookin">
                  Community
                </a>
              </li>
              <li className="mt-[1rem]">
                <a href="https://invest.whatscookin.us/">Invest</a>
              </li>
            </ul>
            <div>  <p className="text-white mt-[3rem] md:hidden block">All rights reserved@2021 </p> </div>
            <div>  <p className="text-white mt-[3rem] text-center md:hidden flex gap-3"> Powered by Ceramic <a href="https://ceramic.network" target="_blank" rel="noopener noreferrer">  <img src={ceramic} className='w-[30%]' alt="" /></a></p></div>
          </figure>
        </aside>
      </div>
    </footer>
  );
};

export default Footer;
