import React from "react";
import About from "../components/About";
import FutureCounter from "../components/Counter";
import Counter from "../components/Counter";
import Layout from "../components/Layout";
import Paper from "../components/Paper";
import Sources from "../components/Sources";
import Wrapper from "../components/Wrapper";

const Home = () => {
  return (
    <div>
      <Layout>
        <Wrapper />
        {/* < FutureCounter/> */}
        <div id="paper" className="py-[2.2rem] mb-[4rem] ">
          <Paper />
        </div>
        <div id="open" className="py-[2.2rem]">
          <Sources />
        </div>
        <div id="about" className="py-[2.2rem]">
          <About />
        </div>
        <p className="text-center text-white font-bold md:text-3xl text-xl my-[2rem]">
          Any Addressable Claim can be included -{" "}
          <a href="https://live.linkedtrust.us/" className="underline">please add yours!</a>{" "}
        </p>
      </Layout>
    </div>
  );
};

export default Home;
