import { useState, useRef, useEffect } from "react";
import TypeWriterEffect from "react-typewriter-effect";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(false)
  const chatBottomRef = useRef(null);

  useEffect(() => {
    chatBottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

 
  const handleSubmit = (event) => {
    event.preventDefault();
    const newMessage = {
      text: message,
      sender: "user",
      timestamp: new Date().getTime(),
    };
    setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);

    fetch(
      `https://2qmefbax1i.execute-api.us-west-1.amazonaws.com/chatbot?input_text=${message}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const responseMessage = {
          text: data,
          sender: "bot",
          timestamp: new Date().getTime(),
        };
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          responseMessage,
        ]);
      })
      .catch((error) => {
        setError(error.message);
      });

    setMessage("");
  };

  return (
    <div className="h-full z-[100] relative rounded-lg bg-[#00E3A5] flex flex-col justify-end pb-[0.5rem]">
      <div className="flex flex-col gap-2 h-5/5 px-4 overflow-y-auto z-40 rounded-lg">
        {chatHistory.map((message, index) => {
          return (
            <div
              key={index}
              className={`flex flex-col gap-1 items-${message.sender}-end ${
                message.sender === "user" ? "sender" : "bot"
              }`}
            >
              <span className="text-sm font-semibold text-gray-500">
                {message.sender === "user" ? "You" : "Bot"}
              </span>
              <span
                className={`px-4 py-2 rounded-md ${
                  message.sender === "user" ? "blue" : "gray"
                } -100 text-${
                  message.sender === "user" ? "blue" : "gray"
                }-800 max-w-2/3`}
              >
                {message.sender === "user" ? (
                  <span> {message.text}</span>
                ) : (
                  
                  <TypeWriterEffect
                    textStyle={{ fontFamily: "Red Hat Display" }}
                    cursorColor="black"
                    text={message.text}
                
                    eraseSpeed={100}
                  />
                  
                )}
             
              </span>
            {error && <p className="bg-white border-[1px] border-[red]  text-[red] p-[1rem] w-full"> {error} please refresh</p>}
              <span className="text-xs text-gray-500 text-right">
                {new Date(message.timestamp).toLocaleTimeString()}
              </span>
            </div>
          );
        })}
        <div ref={chatBottomRef} />
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex items-center gap-2 px-4 z-30"
      >
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow border rounded-full border-blue-500 px-4 py-2"
        />
        <button
          type="submit"
          className="rounded-full px-4 py-2 bg-blue-500 text-white font-semibold"
        >
          Send
        </button>
      </form>
      <div className="custom-shape-divider-bottom-1681098485">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Chat;
