import particle from "../images/particles.svg";
import people from "../images/people.png";
import star from "../images/star.png";
import org from "../images/organization.png";
import product from "../images/digital.png";
import impact from "../images/impact.png";

const Wrapper = () => {
  const figure = [
    {
      img: people,
      title: "People",
      class: "one",
    },
    {
      img: star,
      title: "Events",
      class: "two",
    },
    {
      img: org,
      title: "Orgs",
      class: "three",
    },
    {
      img: product,
      title: "Products",
      class: "four",
    },
    {
      img: impact,
      title: "Impact",
      class: "five",
    },
  ];
  return (
    <header className="mt-[9rem]">
    <div className="md:hidden block text-center text-white w-4/5 m-auto">
        <h2 className="text-[#80AB9E] text-xl">A minimal schema </h2>
        <p className="text-3xl font-bold">for expressing linked trust claims permissionlessly</p>
      </div>
   
    <section className=" md:h-[80vh] h-[60vh] relative">
      
      <img src={particle} alt="" className="absolute h-full w-full" />
      {figure.map((fig) => {
        return (
          <figure key={fig.class}
            className={`flex flex-col items-center justify-center w-[150px] border h-[100px] bg-gradient-to-b from-white-opacity-3 ${fig.class}`}
          >
            <img src={fig.img} alt="" className="w-[60px] h-[60px]" />
            <h2 className="text-white font-bold">{fig.title}</h2>
          </figure>
        );
      })}
    </section>
    </header>
  );
};

export default Wrapper;
