import connect from "../images/connect.png";
import carbon from "../images/carbon.png";
import grome from "../images/grome.png";
import one from "../images/01.png";
import two from "../images/02.png";
import three from "../images/03.png";
import bg from "../images/recbg.png";
import bg2 from "../images/bg2.png";

const Sources = () => {
  return (
    <div className="bg-[#0A1619] py-4">
      <p className="text-center font-bold text-3xl my-8 text-white">
        Open Source
      </p>

      <div className="relative">
        <aside className="flex justify-end">
          <figure className="flex relative justify-around rounded-l-[300px] md:w-[90%] w-full p-[1.5rem]">
            <img
              src={bg}
              alt=""
              className="absolute h-full w-full right-0 top-0"
            />
            <div className="flex items-center gap-8 md:basis-[60%] basis-auto">
              <span>
                <img src={one} alt="" className="w-[60px] h-[60px] md:block hidden" />
              </span>
              <span>
                <h2 className="text-white font-bold my-[1rem] ">
                  <a href="#" className="">
                    LinkedTrust Open Data
                  </a>
                </h2>
                <p className="text-[#80AB9E] z-10 relative md:text-[18px] text-[12px]">
                  Signed Claims written to IPFS via ComposeDB Streams. Claims are open, transparent,
                  and permissionless: anyone may make a new attestation about the subject of a claim, or about the claim itself.
                  The view of the trust claim graph may filter based on credibility of the signer, but the raw data is available for anyone to inspect. ,{" "}
                </p>
              </span>
            </div>
            <div>
              <img src={connect} alt="" />
            </div>
          </figure>
        </aside>
        <aside className="flex md:w-4/5 w-full my-[2rem] rounded-r-[300px] justify-around items-center p-[1rem] relative">
          <img
            src={bg2}
            alt=""
            className="absolute h-full w-full right-0 top-0 "
          />
          <div>
            <img src={carbon} alt="" />
          </div>
          <div className="flex items-center gap-8 md:basis-[60%] basis-auto">
            <span>
              <h2 className="text-white font-bold my-[1rem] text-right">
                <a href="http://cooperation.org/credentials/v1/" >
                  LinkedClaims Vocabulary 1.0
                </a>
              </h2>
              <p className="text-[#80AB9E] z-10 relative md:text-[18px] text-[12px] text-right">
                <a href="http://cooperation.org/credentials/v1/" className="link-style">
                  Composable Claims Vocabulary
                </a> includes the ability to specify a 3rd-party source, using a digest multibase hash to demonstrate that the source has not changed. This allows importation of existing web2.0 data
                into a durable, decentralized graph,{" "}
              </p>
            </span>
            <span>
              <img src={two} alt="" className="w-[auto] h-[60px] md:block hidden" />
            </span>
          </div>
        </aside>
        <aside className="flex justify-end">
          <figure className="flex relative justify-around rounded-l-[300px] md:w-[90%] p-[1.5rem]">
            <img
              src={bg}
              alt=""
              className="absolute h-full w-full right-0 top-0"
            />
            <div className="flex items-center gap-8 md:basis-[60%] basis-auto">
              <span>
                <img src={three} alt="" className="w-[auto] h-[60px] md:block hidden" />
              </span>
              <span>
                <h2 className="text-white font-bold my-[1rem]">
                  <a href="https://github.com/Cooperation-org/LinkedClaims" >
                    LinkedClaims Examples
                  </a>
                </h2>
                <p className="text-[#80AB9E] z-10 relative md:text-[18px] text-[12px]">
                  <a href="https://github.com/Cooperation-org/LinkedClaims" className="link-style">
                    LinkedClaims Example Repo
                  </a> contains narratives and example verifiable credentials for use cases from disaster recovery to microcredentials. In particular, having the ability of the recipients to attest to impact is a powerful model. Combining reputation across domains results in a more resilient trust model.,{" "}
                </p>
              </span>
            </div>
            <div>
              <img src={grome} alt="" />
            </div>
          </figure>
        </aside>
      </div>
    </div>
  );
};

export default Sources;
